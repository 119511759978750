import { makeBetterEnum } from './betterEnum';

export const DatasetCadence = makeBetterEnum(
  'Never',
  'Once',
  'Quarterly',
  'Yearly'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DatasetCadence =
  (typeof DatasetCadence)[keyof typeof DatasetCadence];

export const CanonicalDatasetKind = makeBetterEnum(
  'BillOfMaterials',
  'Buildings',
  'GhgRemoval',
  'CapitalExpenses',
  'CareInstructions',
  'CarbonCreditRetirements',
  'ChartOfAccounts',
  'CloudCosts',
  'CloudUsage',
  'CloudUsageAndCost',
  'CorporateInvestments',
  'CostOfRevenue',
  'CryptoHoldings',
  'CryptoTransactions',
  'CustomEmissionsFactor',
  'DataCenters',
  'DirectEmissions',
  'DownstreamLogistics',
  'EmployeeTravelExpenses',
  'Employees',
  'EndOfLifeTreatmentOfSoldProducts',
  'EnergyAttributeCertificatesPurchased',
  'Factories',
  'Financials',
  'Flights',
  'Food',
  'HistoricalResults',
  'Hotels',
  'MaterialAggregate',
  'MaterialContents',
  'OperatingExpenses',
  'Packaging',
  'ProductsPurchased',
  'ProductsSold',
  'PrivateFlights',
  'PurchaseOrders',
  'Rails',
  'Refrigerants',
  'Revenue',
  'Sites',
  'SupplierFactoriesTier1',
  'SupplierUpstreamLogistics',
  'TextileProductSales',
  'UpstreamLogistics',

  // UseOfSoldProducts is the new naming convention, with one exception that predates it:
  // UseOfGoods is unused, and UsePhaseOfGoods is the dataset kind that represents product
  // lifetime consumption.
  'UseOfGoods',
  'UseOfSoldProductsDataTransfer',
  'UseOfSoldProductsRefrigerants',
  'UseOfSoldProductsFuels',
  'UseOfSoldProductsVehicles',
  'UseOfSoldProductsCommonRetail',
  'UsePhaseOfGoods',
  'Utilities',
  'SoldProductCircularity',
  'Vehicles',
  'Waste',
  'WaterUsage',
  'WeightOfGoodsPurchased',

  // To be used for finance datasets
  'FinancedEmissions',

  // Social + Governance datasets
  'BriberyAndCorruption',
  'HumanRightsViolations',
  'Lobbying',
  'OperationalPayments',
  'SafetyIncidents',
  'WorkplaceReports',
  'WorkforceSocial',

  // Other CSRD
  'Microplastics', // Not used in production anywhere, for demo purposes only though we expect to add this in the future
  'Substances',
  'SubstanceInflow'
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CanonicalDatasetKind =
  (typeof CanonicalDatasetKind)[keyof typeof CanonicalDatasetKind];
